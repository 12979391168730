.team-member-row {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  margin: 0 15px;
}

.team-member-row > div {
  flex-basis: 100%;
}

@media (min-width: 576px) {
  .team-member-row > div {
    flex-basis: 50%;
  }

  .team-member-row > div:nth-child(even) {
    justify-content: flex-start;
  }

  .team-member-row > div:nth-child(odd) {
    justify-content: flex-end;
  }
}

@media (min-width: 1085px) {
  .team-member-row > div:nth-child(odd) > div {
    margin-right: -40px;
  }
}

@media (min-width: 1200px) {
  .team-member-row > div:nth-child(odd) > div {
    margin-right: -50px;
  }

}
