.main {
  /* min-height: calc(100vh - var(--top-menu-height)); */
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 2;

  --font-size: 20px
}

.container {
  height: 100%;
  position: relative;
  mix-blend-mode: screen;
  z-index: 2;
}

.page-row {
  height: 100%;
  z-index: 2;
  position: relative;
  padding-bottom: 300px;
}

.info-container {
  position: relative;
}

.image-container {
  text-align: center;
  padding-bottom: 50px;
}

.image {
  margin: 0 auto;
  max-width: 200px;
}


.white{
  color: white;
}


.dates-container{
  z-index: 2;
  margin-bottom: 30px;
}

.date-item{
  font-family: 'Montserrat';
  font-weight: 400;
  mix-blend-mode: screen;
  text-shadow: 0px 0px 1px rgb(137 251 253), 0px 0px 4px rgb(137 251 253);
  text-align: center;
  color: white;
  font-size: var(--font-size);
  margin-bottom: 10px;
}


.loading-container{
  position: relative;
}


.state-container h2{
  font-family: 'Montserrat';
  font-weight: 400;
  mix-blend-mode: screen;
  text-shadow: 0px 0px 1px rgb(137 251 253), 0px 0px 4px rgb(137 251 253);
  text-align: center;
  color: var(--color-primary);
  font-size: var(--font-size);
  margin-bottom: 10px;
  text-align: center;
  z-index: 2;
  position: relative;
}

.above-mint-button{
  margin-bottom: 40px;
}

.mint-button{
  cursor: pointer;
  width: 400px;
}


@media (min-width: 768px) {

  .page-row {
    padding-bottom: 200px;
  }

}


@media (min-width: 992px) {

  .main {
    --font-size: 24px;
  }


  .page-row{
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .image-container {
    margin-right: 50px;
    padding-bottom: 0;
  }

  .image {
    margin: 0 auto;
    max-width: 300px;
  }
  
  .info-container {
    max-width: 550px;
    width: 485px;
  }


}