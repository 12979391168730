.background-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.background-gif{
  height: 100%;
  width: 100%;
  background-size: cover;
}