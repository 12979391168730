/* this is done so the full image can be seen but not as large */
.header-image-container {
  overflow-y: visible;
  overflow-x: hidden;
  position: relative;
  height: 135px;
  text-align: center;
  max-width: 100vw;
  z-index: 1;
}

.header-image {
  margin: 0 auto;
  padding: 0;
  border: 0;
  mix-blend-mode: screen;
  max-width: 1200px;
  /* width: 100vw; */
  width: 110vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.tagline {
  position: absolute;
  right: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  color: var(--color-primary);
  font-size: 12px;
}
.white {
  color: white;
  text-shadow: 0px 0px 1px rgb(137 251 253), 0px 0px 4px rgb(137 251 253);
}

@media (min-width: 576px) {
  .header-image-container {
    overflow-x: visible;
    top: -15px;
  }
  .header-image {
    width: 100vw;
  }
}

@media (min-width: 768px) {
  .header-image-container {
    height: 180px;
    top: -40px;
  }
}

@media (min-width: 992px) {
  .header-image-container {
    height: 165px;
    top: -60px;
  }
}

@media (min-width: 1080px) {
  .header-image-container {
    height: 200px;
  }
}

@media (min-width: 1200px) {
  .header-image-container {
    height: 205px;
  }
}
