.desktop {
  display: none;
  position: sticky;
  top: 0;
  z-index: 5;
}

.mobile{
  position: sticky;
  top: 0;
  z-index: 5;
}

@media (min-width: 992px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}
