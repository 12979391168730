.main {
  /* min-height: calc(100vh - var(--top-menu-height)); */
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 2;

  --font-size: 20px;

}

.content{
  position: relative;
  z-index: 3;
  margin-bottom: 10px;
}

.text{
  color: white;
  text-align: center;
  font-size: 24px;
}

.submit-button{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  padding: 0 10px;
  cursor: pointer;
  letter-spacing: 3px;
  color: rgb(40, 42, 42);
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  margin-left: 8px;
  width: 190px;
  font-weight: 500;
  position: relative;
  margin: auto;
  margin-top: 16px;
  padding: 6px 3px;
}

.items-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 100px;
}

.coming-soon-container{
  width: 400px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: 100px;
}

.input-container{
  text-align: center;
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .main {
    --font-size: 24px;
  }
}
