/* GLOBAL CSS VARIABLES */
:global(:root) {
  /* --color-primary: rgb(68, 255, 163); */
  --color-primary: rgba(101, 231, 235, 1);
  --menu-text-color: rgba(173, 173, 173, 1);
  --font-color: rgba(162, 253, 254, 1);
  --faq-question-color: rgba(137, 228, 233);

  /* Title */
  --title-max-width: 600px;

  /* Font sizes */
  --font-size-main: 19px;
  --font-size-small: 17px;

  --header-font-size: 22px;
  --subheader-font-size: 22px;
  --text-font-size: 19px;

  --white-glow: 0px 0px 1px rgb(137 251 253), 1px 2px 10px rgba(162, 253, 254, 1);
  --blue-glow: 1px 2px 15px rgb(253, 251, 137 / 100%);


  --text-glow: 1px 2px 10px rgba(162, 253, 254, 1);

  /* Heights */
  --top-menu-height: 50px;
  --footer-height: 200px;
}

:global(.container) {
  max-width: 1230px;
  padding: 0 20px;
  margin: 0 auto;
}

a {
  color: var(--color-primary);
  cursor: pointer;
  transition-duration: 0.3s;
}
a:hover {
  color: var(--color-primary);
}

.main {
  position: relative;
  padding-bottom: var(--footer-height);
}

@media (min-width: 768px) {
  :global(:root) {
    /* Font sizes */
    --header-font-size: 22px;
    --subheader-font-size: 22px;
    --text-font-size: 19px;

    /* Heights */
    --footer-height: 100px;
  }
}

@media (min-width: 992px) {
  :global(:root) {
    --font-size-main: 19px;
    --font-size-small: 17px;

    --top-menu-height: 50px;
  }
}
