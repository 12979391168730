.container {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  position: relative;
}

.container:last-child {
  margin-bottom: 0;
}

.road-map-item {
  position: relative;
  align-items: center;
  padding: 20px 20px 20px 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  text-align: left;
  color: white;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  margin: 0 10px;
}

.road-map-item.lights-on {
  opacity: 1;
}

.flicker-container {
  position: absolute;
  top: 25px;
  left: 15px;
  height: 100px;
  width: 100px;
  display: none;
}

.flicker {
  width: 100%;
}

.title {
  font-size: 19px;
  color: rgb(137, 228, 233);
  font-weight: bold;
  margin-bottom:0;
}

.subtitle {
  font-size: 17px;
  color: white;
  font-weight: bold;
  margin-bottom: 8px;
}

.content-container{
  position: relative;
  z-index: 5;
  color:white;
}

@media (min-width: 768px) {
  .road-map-item {
    padding: 40px 40px 40px 135px;
    min-height: 225px;
    width: 750px;
  }

  .subtitle{
    margin-bottom: 15px;
  }

  .flicker-container {
    display: block;
  }
}

@media (min-width: 992px) {
  .container {
    margin-bottom: 72px;
  }

  .title {
    font-size: 24px;
  }
}
