.mobile-art-example-container {
  overflow-y: visible;
  position: relative;
  text-align: center;
  max-width: 360px;
  top: -40px;
  margin: 0 auto;
}

.mobile-art-example,
.art-example {
  width: 100%;
  mix-blend-mode: screen;
  z-index: 2;
  position: relative;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media (min-width: 576px) {
  .mobile-art-example-container {
    top: -20px;
  }
}

@media (min-width: 768px) {
  .mobile-art-example-container {
    top: -75px;
    max-width: 500px;
    height: 400px;
  }
}



@media (min-width: 992px) {
  .mobile-art-example-container {
    max-width: 435px;
    top: -54px;
    margin: 0;
  }
}

@media (min-width: 1080px) {
  .header-image-container {
    max-width: 461px;
  }
}

@media (min-width: 1200) {
}
