
@keyframes circleSpin{
  0%   {transform: translate(-50%, -50%) rotate(0deg)}
  50% {transform: translate(-50%, -50%) rotate(180deg)}
  100% {transform: translate(-50%, -50%) rotate(360deg)}
}


.loader-parent{
    width: 50px;
    height: 50px;
}

.absolute{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.inline{
    position: relative;
    margin: 0 auto;
}

.negative-margin{
    margin-top:  calc(-1 * var(--top-nav-height));
}


.loader-child-1{
    position: absolute;
    height: 50px;
    width: 50px;
    border-top: 1px solid rgb(54, 215, 183);
    border-left: 1px solid rgb(54, 215, 183);
    border-image: initial;
    border-radius: 100%;
    transition: all 2s ease 0s;
    border-bottom: none;
    border-right: none;
    top: 50%;
    left: 50%;
    animation: circleSpin 1s linear 0s infinite normal none running;
    transform: translate(-50%, -50%);
}

.loader-child-2{
	position: absolute;
    height: 45px;
    width: 45px;
    border-top: 1px solid rgb(54, 215, 183);
    border-left: 1px solid rgb(54, 215, 183);
    border-image: initial;
    border-radius: 100%;
    transition: all 2s ease 0s;
    border-bottom: none;
    border-right: none;
    top: 50%;
    left: 50%;
    animation: circleSpin 1s linear 0.2s infinite normal none running;
    transform: translate(-50%, -50%);
}

.loader-child-3{
    position: absolute;
    height: 40px;
    width: 40px;
    border-top: 1px solid rgb(54, 215, 183);
    border-left: 1px solid rgb(54, 215, 183);
    border-image: initial;
    border-radius: 100%;
    transition: all 2s ease 0s;
    border-bottom: none;
    border-right: none;
    top: 50%;
    left: 50%;
    animation: circleSpin 1s linear 0.4s infinite normal none running;
    transform: translate(-50%, -50%);
}