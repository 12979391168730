.section-container {
  position: relative;
  overflow-y: visible;
  position: relative;
  text-align: center;

  padding: 64px 0;
}

.child-container {
  z-index: 2;
  position: relative;
  mix-blend-mode: screen;
}



@media (min-width: 992px) {
  .section-container {
    padding: 64px 0;
  }
}
