.title-container {
  position: relative;
  z-index: 2;
  mix-blend-mode: screen;
  margin-bottom: 16px;
}

.title {
  position: relative;
  margin: 0 auto;
  padding: 0;
  border: 0;
  mix-blend-mode: screen;
  max-width: var(--title-max-width);
  z-index: 2;
  width: 100%;
}

@media (min-width: 992px) {
  .title-container {
    margin-bottom: 32px;
  }
}
