* { 
	box-sizing: border-box;
}
html, body {
	min-height: 100%;
	color: #000;
}

body, ul, ol, dl {
  margin: 0;
}

body {
  margin: 0;
  background-color: black;
  font-family: 'Montserrat', sans-serif;
}


h1,
h2,
h3,
h4 {
  margin: 0;
}

body p,
label,
body ul li{
  font-weight: 400;
  line-height: 1.4
}

body h1,
body h2,
body h3,
body h4,
body h5 {
  line-height: 1.4
}