.above-fold{
  position: relative;
  margin-bottom: 32px;
}



@media (min-width: 992px) {
  .above-fold{
    margin-bottom: 64px;
  }
  
  
  .second-row{
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
}


@media (min-width: 1080px) {

  .above-fold{
    /* Trying this out */
    /* height: calc(100vh - var(--top-menu-height)); */
    height: 835px;
  }

}
