.road-map-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 2;
}
.benefits-title {
  margin-bottom: 0;
}
.benefits-list {
  margin-top: 5px;
  padding-left: 15px;
}
.road-map-row p {
  /* line-height: 1.4; */
  color: white;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  z-index: 2;
  margin-top: 0;
  line-height: 1.6;
  mix-blend-mode: screen;
}

.road-map-row a {
  z-index: 1;
  position: relative;
}

.road-map-row ul li {
  line-height: 1.4;
  font-weight: 500;
  font-size: 16px;
}

.road-map-row ul li::before {
  content: '\2022';
  color: var(--font-color);
  font-weight: bold;
  display: inline-block;
  width: 14px;
  margin-left: -16px;
  font-size: 16px;
}
.status {
  font-size: 16px;
  margin-top: 24px;
  color: var(--font-color);
}
.status-inner {
  color: white;
}
@media (min-width: 768px) {
  .road-map-outside {
    position: relative;
    width: 750px;
    margin: 0 auto;
  }
}
