.mint-button{
  /* color: #ffffff; */
  color: black;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 400;
  background-color: var(--color-primary);
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  box-shadow: rgb(101 119 134 / 31%) 0px 0px 15px, rgb(101 119 134 / 44%) 0px 0px 3px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 10px;
}


.mint-button:hover{

}