.desktop-menu {
  background-color: #282a2a;
  width: 100%;
  height:  var(--top-menu-height);
  font-family: 'Sofia Pro', sans-serif;
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.container {
  height: 100%;
}

.row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-side {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  max-width: 500px;
}

.divider {
  position: relative;
}
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 1px;
  background-color: var(--menu-text-color);
}

.right-side {
  display: flex;
  align-items: center;
  height: 100%;
  /* overflow: hidden; */
}

.right-side svg {
  /* padding: 0 10px; */
}

/* MENU ITEMS */
.menu-item-text {
  color: var(--menu-text-color);
  text-decoration: none;
}

.menu-item-text:hover {
  color: var(--color-primary);
  text-decoration: underline;
}

@media (min-width: 992px) {
  .left-side {
    max-width: 560px;
  }
}

@media (min-width: 1080px) {
  .left-side {
    /* max-width: 700px; */
  }
}

@media (min-width: 1200px) {
  .left-side {
    /* max-width: 790px; */
  }
}
