/* gif */
.coming-soon-container {
  position: relative;
  /* top: -80px; */
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}
.coming-soon {
  margin: 0 auto;
  width: 100%;
  mix-blend-mode: screen;
  cursor: pointer;
}



@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .coming-soon-container {
    max-width: none;
  }
  .coming-soon {
    margin: 0 auto;
    max-width: 600px;
  }

}

@media (min-width: 992px) {
  .coming-soon-container {
    top: -70px;
  }

}

@media (min-width: 1143px) {

  .coming-soon-container {
    top: -94px;

  }

}
