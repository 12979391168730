.roadmap-progress-wrapper {
  z-index: 3;
  padding-top: 20px;
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 120px;
  mix-blend-mode: screen;
  display: none;
}

.roadmap-progress-line {
  margin: auto;
  border-radius: 10px;
  width: 7px;
  height: 100%;
  background-color: var(--faq-question-color);
}

.roadmap-image-wrapper {
  transition: top 0.5s ease-in-out, -webkit-transform 1s ease-in-out;
  transition: top 0.5s ease-in-out, transform 1s ease-in-out;
  transition: top 0.5s ease-in-out, transform 1s ease-in-out,
    -webkit-transform 1s ease-in-out;
  -webkit-transform: rotatex(0deg);
  transform: rotatex(0deg);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 75%;
}

.roadmap-image {
  position: relative;
  width: 100%;
  z-index: 1;
}

@media (min-width: 768px) {
  .roadmap-progress-wrapper {
    display: block;
  }
}
