.faq-container {
  position: relative;
  padding-bottom: 100px;
}

.faq-container {
  overflow-y: visible;
  position: relative;
  text-align: center;
}

.faq-title {
  margin: 0 auto;
  padding: 0;
  border: 0;
  mix-blend-mode: screen;
  max-width: var(--title-max-width);
}

.faq-row {
  text-align: center;
  padding: 0 10px;
}

.faq-item {
  text-align: center;
  display: inline-block;
  margin-bottom: 10px;
}

.faq-item:last-child {
  margin-bottom: 0;
}

.question {
  font-size: var(--font-size-main);
  color: var(--faq-question-color);
  margin-bottom: 10px;
  font-weight: bold;
}

.answer {
  font-size: var(--font-size-small);
  color: white;
  margin-top: 0;
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .faq-item {
    width: 750px;
    margin-bottom: 15px;
  }

  .faq-item {
    text-align: left;
  }
}
