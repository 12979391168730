

.container,
.price-container{
  display: flex;
  /* max-width: 400px; */
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin-top: 20px;
}


.button-item{
  width: 55px;
  height: 55px;
  border-radius: 100%;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 6px solid var(--color-primary);
  cursor: pointer;
}

.minus{
  position: relative;
  font-size: 60px;
  top: -5px;
}

.plus{
  position: relative;
  font-size: 55px;
}


.cur-number{
  font-size: 30px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}




.price{
  font-family: 'Montserrat';
  font-weight: 400;
  text-shadow: 0px 0px 1px rgb(137 251 253), 0px 0px 4px rgb(137 251 253);
  text-align: center;
  color: white;
  font-size: 22px;
}