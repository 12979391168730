

.counter{
  position: relative;
  font-family: 'Montserrat';
  width: 100%;
  flex-basis: 100%;
  font-weight: 400;
  font-size: 26px;
  position: relative;
  mix-blend-mode: screen;
  color: var(--color-primary);
  text-shadow: 0px 0px 1px rgb(137 251 253), 0px 0px 4px rgb(137 251 253);
  margin-top: 20px;
}


.counter span{
  color: white;
}