

.container{ 
  height: 100px;
  width: 100px;
  position: relative;
  margin: 10px auto 0;
  /* z-index: -1; */
}

.image{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  cursor: pointer;
}