.connect-wallet {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--color-primary);
  padding: 0 10px;
  cursor: pointer;
  letter-spacing: 3px;
  color: rgb(40, 42, 42);
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  margin-left: 8px;
  width: 190px;
  /* overflow: hidden; */
  font-weight: 500;
  position: relative;
}



.connect-wallet:hover{
  text-decoration: none;
  color: black;
  background-color: rgba(162, 253, 254, 1);
}

.disconnect{
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  background-color: rgba(162, 253, 254, 1);
  padding: 10px 5px;
  border-radius: 10px;
  width: 205px;
  text-align: center;
}

.disconnect::after{
  content: '';
  position: absolute;
  left: 42%;
  bottom: 100%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(162, 253, 254, 1);;
  clear: both;
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .connect-wallet {
  }
}
