.item-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.item-inside {
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0 40px 20px;
  text-align: center;

  width: 100%;
}

.image-container {
  /* margin-bottom: 10px; */
  height: 200px;
}

.image {
  width: 225px;
}

.no-glow{
  width: 111px;
  position: relative;
  top: 77px;
}

.member-info {
  flex-basis: 100%;
  position: relative;
  z-index: 2;
}

.member-info * {
  line-height: 1.1;
}

.member-info a {
  display: inline-block;
}

.name {
  font-size: var(--font-size-main);
  color: rgb(137, 228, 233);
  font-weight: bold;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.title {
  font-size: var(--font-size-main);
  color: white;
  font-weight: bold;
  margin-bottom: 8px;
}

.social-row {
  margin-bottom: 8px;
}

.calendly-notice {
  font-size: 13px;
  color: white;
  max-width: 250px;
  line-height: 15px;
  margin: 0 auto;
}

.instagram-logo-container {
  display: inline-block;
  width: 35px;
  height: 26px;
  position: relative;
  top: 2px;
  margin-right: 6px;
}

.chain-logo-container {
  padding-right: 8px;
  width: 40px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.instagram-logo-container:hover,
.chain-logo-container:hover {
  -webkit-transform: translate(2px, -2px);
  transform: translate(2px, -2px);
}

.instagram-logo,
.chain-logo {
  width: 100%;
}

@media (min-width: 576px) {
  .item-inside {
    padding: 0;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .item-inside {
    display: flex;
    text-align: left;
    padding: 0 0 80px;
    text-align: left;
  }

  .image-container {
    margin-bottom: 10px;
    height: 150px;
    /* width: 300px; */
    width: 219px;
    position: relative;
    overflow: visible;
    margin-bottom: 0;
    padding-right: 0;
  }

  .image {
    /* width: 225px; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .no-glow{
    width: 130px;
  }

  .calendly-notice {
    margin-left: 0;
  }
}



@media (min-width: 992px) {
  .image-container {
    width: 275px;
  }
}

@media (min-width: 1085px) {
  .item-inside {
    padding: 0 40px 75px;
    width: 450px;
  }
}

@media (min-width: 1200px) {
  .item-inside {
    width: 525px;
  }
}
