.header-copy-container {
  position: relative;
  margin: 0 10px;
  padding: 0 10px;
  text-align: center;

  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.header-copy {
  font-family: 'Montserrat';
  width: 100%;
  flex-basis: 100%;
  color: white;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  z-index: 2;
  line-height: 30px;
  margin-top: 0;
  mix-blend-mode: screen;

  text-shadow: 0px 0px 1px rgb(137 251 253), 0px 0px 4px rgb(137 251 253);
}

.header-copy a{
  display: inline-block;
}

.list-item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
.dot {
  width: 26px;
}

@media (min-width: 992px) {
  .header-copy-container {
    position: relative;
    text-align: left;
    margin: 0;
    width: 800px;
    max-width: 710px;
    padding: 0;
  }
  .header-copy {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .dot {
    margin-right: 10px;
  }
}

@media (min-width: 1080px) {

}

@media (min-width: 992px) {
}

@media (min-width: 1200) {
}
