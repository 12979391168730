.mobile-menu {
  background-color: #282a2a;
  z-index: 2;
  color: #4a4a53;
  height: var(--top-menu-height);
  font-size: 1rem;
  top: 0;
  z-index: 5;
}

.top-row {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.slide-container {
  position: fixed;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  pointer-events: none;
  z-index: 10;
  /* display: none; */
}

.slide-nav-open{
  background-color: black;
}

.slide-container .bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  background: #000;
  z-index: 10;
}

.slide-content {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: black;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateX(-100%);
  transition: transform 0.3s;
  will-change: transform;
  z-index: 10;
}

.slide-nav-open .slide-content {
  transform: translateX(0);
}

.nav-items {
  height: 100%;
  overflow: hidden;
}

.nav-item {
  background-color: #282a2a;
  height: calc((100vh - var(--top-nav-height)) / 3);
  height: calc(((var(--vh, 1vh) * 100) - var(--top-nav-height)) / 3);

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px !important;
  border-left: none;
  border-right: none;
  cursor: pointer;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  z-index: 10;
  text-decoration: none;
}
