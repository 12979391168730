.logo {
  color: var(--menu-text-color);
  /* color: rgba(173, 173, 173, 1); */
  /* cursor: pointer; */
  font-size: 20px;
  letter-spacing: 3px;
  z-index: 2;
  text-decoration: none;
}

.logo:hover{
  color: var(--color-primary);
}

.height{
  height: 11px;
}

.blue {
  color: var(--color-primary);
}


@media (min-width: 992px) {
  .logo{
    font-size: 22px;
  }
}