


.container{
  margin-top: 50px;
  /* border-width: 2px;
  border-style: solid;
  box-shadow: var(---white-glow);
  border: 1px solid white;
  padding:15px;
  background-color: #1e1e1e; */
}


.button-container{
  width: 200px;
  margin: 0 auto;
}

.container h3{
  font-family: 'Montserrat';
  width: 100%;
  flex-basis: 100%;
  font-weight: 400;
  mix-blend-mode: screen;
  text-shadow: 0px 0px 1px rgb(137 251 253), 0px 0px 4px rgb(137 251 253);
  text-align: center;
  padding-bottom: 10px;
  color: var(--color-primary);
}


