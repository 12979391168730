.footer {
  background-color: #282a2a;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: rgb(101 119 134 / 31%) 0px 0px 15px, rgb(101 119 134 / 44%) 0px 0px 3px 1px; */
  position: absolute;
  bottom: 0;
  height: var(--footer-height);
}

.footer-container {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  mix-blend-mode: screen;
}

.footer-row {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 0;
}

/* divider */
.divider {
  position: relative;
}
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 1px;
  background-color: var(--menu-text-color);
}

.center,
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px 0;
  margin: 5px 0;
}

/* left */
.left {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}
.learn-more,
.read-more {
  margin-bottom: 5px;
  font-size: 15px;
  display: block;
}

.learn-more {
  color: white;
  /* text-shadow: var(--white-glow); */
  text-shadow: 1px 2px 10px rgba(162, 253, 254, 1);
  /* text-shadow: 1px 2px 15px rgb(204 101 199 / 30%); */
  text-decoration: none;
  /* text-shadow: 0px 0px 1px rgb(137 251 253), 0 0 25px #fff, 0 0 20px #fff; */
}

.read-more {
  /* text-shadow: 1px 2px 15px rgba(253, 251, 137 / 100%); */
  text-shadow: 1px 1px 12px rgba(137, 251, 253, 1);
  color: var(--font-color);
}

/* center */
.center-item-container {
  /* flex-basis: 50%; */
}

.center-column {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.center-item {
  display: block;
  margin: 3px 20px;
  color: rgb(152, 243, 245);
  font-size: 14px;
  cursor: pointer;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered-by {
  font-size: 12px;
  color: rgb(151, 151, 151);
  font-weight: 300;
  margin: 0;
  position: relative;
  top: 6px;
  margin-right: 15px;
}

.etched-logo {
  width: 125px;
}

.rights {
  color: rgb(151, 151, 151);
  font-size: 10px;
  flex-basis: 100%;
  text-align: center;
}

@media (min-width: 768px) {
  .footer-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .divider::after {
    height: 20px;
    width: 1px;
  }
  .left,
  .center,
  .right {
    flex-basis: 33.3333%;
  }

  .left {
    margin-bottom: 0;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
