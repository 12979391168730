.container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  color: white;
  margin: 10px;
  cursor: pointer;
  margin: 0 auto;
}


.image-container{
  height: 150px;
  width: 150px;
  border: 1px solid white;
  border: 5px;
}

.image{
  width: 100%;
}


.number{
  padding-top: 10px;
}

